<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-card-header>
      <b-card-title v-if="title">{{ title }} </b-card-title>
      <!-- <b-card-text class="mx-5 mb-0"> {{ updatedAt }} </b-card-text> -->
      <!-- datepicker -->
      <div class="d-flex">
        <button
          :disabled="!dateFilter"
          class="btn btn-danger mx-2"
          @click="createdAtFilterHandler(null)"
        >
          {{ $t("reset") }}
        </button>
        <div class="d-flex align-items-center">
          <feather-icon icon="CalendarIcon" size="16" />
          <flat-pickr
            v-model="dateFilter"
            class="form-control bg-transparent border-0 shadow-none"
            :dir="isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('no_date_selected') || 'No date Selected'"
            :config="{ mode: 'range', maxDate: new Date(), locale }"
            @on-change="createdAtFilterHandler"
          />
        </div>
      </div>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col md="3" sm="6" class="mb-2 mb-md-0 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="primary">
                <feather-icon size="24" icon="info" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ total }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("total_submitted_individual") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          v-for="item in statisticsItems"
          :key="item.id"
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import { Arabic } from "flatpickr/dist/l10n/ar.js";
import flatPickr from "vue-flatpickr-component";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    flatPickr,
    Arabic,
  },
  data() {
    return {
      updatedAt: "",
      dateFilter: null,
      created_at: null,
      statisticsItems: [
        {
          id: 1,
          icon: "UserIcon",
          color: "light-info",
          title: "",
          subtitle: "",
          customClass: "mb-2 mb-xl-0",
        },
        {
          id: 2,
          icon: "UserIcon",
          color: "light-warning",
          title: "",
          subtitle: "",
          customClass: "mb-2 mb-sm-0",
        },
        {
          id: 3,
          icon: "UserIcon",
          color: "light-success",
          title: "",
          subtitle: "",
          customClass: "",
        },
      ],
    };
  },
  watch: {
    data: {
      handler(val) {
        if (!val) return;
        //
        this.statisticsItems[0].title =
          val[0]?.individual_applications_count || 0;
        this.statisticsItems[0].subtitle = val[0].name_ar;

        //
        this.statisticsItems[1].title =
          val[1]?.individual_applications_count || 0;
        this.statisticsItems[1].subtitle = val[1].name_ar;

        //
        this.statisticsItems[2].title =
          val[2]?.individual_applications_count || 0;
        this.statisticsItems[2].subtitle = val[2].name_ar;

        // updated_at
        // this.calcUpdatedAt();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
    locale() {
      return this.isRTL ? Arabic : "en";
    },
  },
  methods: {
    createdAtFilterHandler(date) {
      if (!date) this.dateFilter = null;
      this.created_at = this.dateFilter
        ? this.isRTL
          ? this.dateFilter?.split(" - ")
          : this.dateFilter?.split(" to ")
        : null;
      this.filterHandler();
    },
    filterHandler() {
      // all filters
      const filters = {
        cards_date: this.created_at ? this.created_at : undefined,
      };
      this.$emit("filters", filters);
    },
    calcUpdatedAt() {
      // todo : take the latest updated_at from the data array
      const updatedAt = new Date(this.data[0].updated_at);
      const now = new Date();
      const diff = Math.abs(now - updatedAt);
      const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
      this.updatedAt = `${this.$t("updated_from")} ${diffDays} ${this.$t(
        "days_ago"
      )}`;
    },
  },
};
</script>
