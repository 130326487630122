<template>
  <b-overlay :show="isLoading">
    <div v-if="stats">
      <!-- cards_date -->
      <!-- Miscellaneous Charts -->
      <b-row class="match-height">
        <b-col lg="12" cols="12">
          <card-statistics-group
            :title="$t('totalSubmittedIndividualDepartments')"
            :data="totalSubmittedIndividualDepartments"
            :total="stats.total_submitted_individual"
            @filters="fetchData"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            icon="EyeIcon"
            :statistic="stats.active_member_verified_count"
            :statistic-title="$t('active_member_verified_count')"
            color="info"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            icon="EyeIcon"
            :statistic="stats.total_submitted_applications"
            :statistic-title="$t('total_submitted_applications')"
            color="info"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            icon="EyeIcon"
            :statistic="stats.total_submitted_team"
            :statistic-title="$t('total_submitted_team')"
            color="info"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            icon="EyeIcon"
            :statistic="stats.total_submitted_individual"
            :statistic-title="$t('total_submitted_individual')"
            color="info"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            icon="EyeIcon"
            :statistic="stats.total_drafts_not_submitted"
            :statistic-title="$t('total_drafts_not_submitted')"
            color="info"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            icon="EyeIcon"
            :statistic="stats.total_drafts_individual"
            :statistic-title="$t('total_drafts_individual')"
            color="info"
          />
        </b-col>

        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            icon="EyeIcon"
            :statistic="stats.total_drafts_team"
            :statistic-title="$t('total_drafts_team')"
            color="info"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            icon="EyeIcon"
            :statistic="stats.total_submitted_pending"
            :statistic-title="$t('total_submitted_pending')"
            color="info"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            icon="EyeIcon"
            :statistic="stats.total_submitted_approved"
            :statistic-title="$t('total_submitted_approved')"
            color="info"
          />
        </b-col>
        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            icon="EyeIcon"
            :statistic="stats.total_submitted_incomplete"
            :statistic-title="$t('total_submitted_incomplete')"
            color="info"
          />
        </b-col>
        <!--  -->

        <b-col xl="2" md="4" sm="6">
          <statistic-card-vertical
            icon="EyeIcon"
            :statistic="stats.total_submitted_rejected"
            :statistic-title="$t('total_submitted_rejected')"
            color="info"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <apex-data-bar-chart :data="stats.governerate" @filters="fetchData" />
        </b-col>
        <b-col cols="12">
          <ApexDataBarChartHospitalDirections
            :data="stats.hospital_directions"
            @filters="fetchData"
          />
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BOverlay } from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import CardStatisticsGroup from "./CardStatisticsGroup.vue";
import ApexDataBarChart from "./ApexDataBarChart.vue";
import ApexDataBarChartHospitalDirections from "./ApexDataBarChartHospitalDirections.vue";

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    StatisticCardVertical,
    CardStatisticsGroup,
    ApexDataBarChart,
    ApexDataBarChartHospitalDirections,
  },
  data() {
    return {
      appliedFilters: {},
    };
  },
  computed: {
    stats() {
      return this.$store.state.home.data;
    },
    isLoading() {
      return this.$store.state.home.isLoading;
    },
    totalSubmittedIndividualDepartments() {
      return this.stats?.total_submitted_individual_departments;
    },
  },
  created() {
    this.$store.dispatch("home/fetchStats");
  },
  methods: {
    fetchData(params) {
      this.appliedFilters = {
        ...this.appliedFilters,
        ...params,
      };

      // remove undefined keys
      const filters = Object.keys(this.appliedFilters).reduce((acc, key) => {
        if (this.appliedFilters[key] !== undefined) {
          acc[key] = this.appliedFilters[key];
        }
        return acc;
      }, {});

      this.$store.dispatch("home/fetchStats", filters);
    },
  },
};
</script>
