<template>
  <b-card no-body v-if="data">
    <b-card-header>
      <!-- title and legend -->
      <b-card-title class="mb-50"> {{ $t("stats") }} </b-card-title>
      <!--/ title and legend -->

      <!-- datepicker -->
      <div class="d-flex">
        <button
          :disabled="!dateFilter"
          class="btn btn-danger mx-2"
          @click="createdAtFilterHandler(null)"
        >
          {{ $t("reset") }}
        </button>
        <div class="d-flex align-items-center">
          <feather-icon icon="CalendarIcon" size="16" />
          <flat-pickr
            v-model="dateFilter"
            class="form-control bg-transparent border-0 shadow-none"
            :dir="isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('no_date_selected') || 'No date Selected'"
            :config="{ mode: 'range', maxDate: new Date(), locale }"
            @on-change="createdAtFilterHandler"
          />
        </div>
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <VueApexCharts
        v-if="hospitalDirections && activeMembers"
        type="bar"
        height="400"
        :options="options"
        :series="series"
      ></VueApexCharts>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { Arabic } from "flatpickr/dist/l10n/ar.js";

const $trackBgColor = "#e9ecef";

export default {
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
  },
  data() {
    return {
      dateFilter: null,
      created_at: null,
    };
  },
  methods: {
    createdAtFilterHandler(date) {
      if (!date) this.dateFilter = null;

      this.created_at = this.dateFilter
        ? this.isRTL
          ? this.dateFilter?.split(" - ")
          : this.dateFilter?.split(" to ")
        : null;
      this.filterHandler();
    },
    filterHandler() {
      // all filters
      const filters = {
        hospital_directions_date: this.created_at ? this.created_at : undefined,
      };
      this.$emit("filters", filters);
    },
  },
  computed: {
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
    locale() {
      return this.isRTL ? Arabic : "en";
    },
    hospitalDirections() {
      return this.data.map((item) => item.name);
    },
    activeMembers() {
      return this.data.map((item) => item.active_members);
    },
    totalSentApplications() {
      return this.data.map((item) => item.total_sent_applications);
    },
    options() {
      return {
        chart: {
          stacked: true,
          id: "hospitalDirections",
          stroke: {
            lineCap: "round",
          },
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
          options: {
            grid: {
              show: false,
              padding: {
                left: -15,
                right: -15,
                top: -12,
                bottom: -15,
              },
            },
            colors: [$themeColors.primary],
            plotOptions: {
              radialBar: {
                hollow: {
                  size: "22%",
                },
                track: {
                  background: $trackBgColor,
                },
                dataLabels: {
                  showOn: "always",
                  name: {
                    show: false,
                  },
                  value: {
                    show: false,
                  },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        xaxis: {
          categories: this.hospitalDirections,
          labels: {
            rotate: 90,
          },
          tickPlacement: "on",
        },
      };
    },
    series() {
      return [
        {
          name: "Active Members",
          data: this.activeMembers,
        },
        {
          name: "Total Sent Applications",
          data: this.totalSentApplications,
        },
      ];
    },
  },
};
</script>
